//
//
//
//
//
//
//
//
//
//
//
//
//

import DrawerLabel from './drawerLabel.vue'
import myform from './form.vue'
import StepForm from './stepForm.vue'
import mytable from './table.vue'

export default {
    components: { mytable, myform, StepForm, DrawerLabel },
    data() {
        return {
            btck: 0,
        }
    },
    methods: {},
}
